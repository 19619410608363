import React, { Suspense, lazy, useState } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

/* loader component for Suspense*/
import PageLoader from "./components/Common/PageLoader";

import Base from "./components/Layout/Base";
import BasePage from "./components/Layout/BasePage";
import Login from "./routes/authentication/Login";
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const Home = lazy(() => import("./components/Dashboard"));
const SubMenu = lazy(() => import("./components/SubMenu/SubMenu"));
const Catalog = lazy(() => import("./routes/Catalog/MasterCatalog/index"));
const Customer = lazy(() => import("./routes/Client/Form"));
const Partner = lazy(() => import("./routes/partner/Form"));
const CustomerList = lazy(() => import("./routes/Client/List/index"));
const PartnerList = lazy(() => import("./routes/partner/List/index"));
const Epmployee = lazy(() => import("./routes/Employee/Form"));
const EmployeeList = lazy(() => import("./routes/Employee"));
const Quotation = lazy(() => import("./routes/quotation/form"));
const CopyQuotation = lazy(() => import("./routes/quotation/copy"));
const QuotationPrint = lazy(() => import("./routes/quotation/print"));
const QuotationList = lazy(() => import("./routes/quotation/list"));
const ShippingOrder = lazy(() => import("./routes/shippingOrder/index"));
const ShippingOrderList = lazy(() => import("./routes/shippingOrder/list"));
const TemplateList = lazy(() => import("./routes/Catalog/Template"));
const AddUser = lazy(() => import("./routes/User/AddUser/index"));
const ChangePassword = lazy(() => import("./routes/User/ChangePass/index"));
const ListUser = lazy(() => import("./routes/User/List"));
const TemplateForm = lazy(() => import("./routes/Catalog/Template/Form"));
const test = lazy(() => import("./routes/quotation/print/test"));
const AddMenu = lazy(() => import("./routes/menu/addMenu"));
const ListMenu = lazy(() => import("./routes/menu/list"));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the curre nt pathname
const listofPages = [
  /* See full project for reference */
];

let routes = [];
try{
  routes = JSON.parse(localStorage.getItem("routes"));
}catch{
  routes = [];
}

const Routes = ({ location }) => {
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };
  const [showMenu, setShowMenu] = useState(
    JSON.parse(localStorage.getItem("menu"))
  );

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'

  const animationName = "rag-fadeIn";

  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            {/* See full project for reference */}
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
      <div>
        {!showMenu && <Login setShowMenu={setShowMenu} />}
        {showMenu && (
          <Base>
            <TransitionGroup>
              <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames={animationName}
                exit={false}
              >
                <div>
                  <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                      <Route path="/Home" component={waitFor(Home)} />
                      {
                        routes?.map(x => {
                          const tempUrl = x.actionUrl;
    
                          return (
                            <Route
                              path={x.name}
                              component={waitFor(lazy(() => import("./routes/"+tempUrl+"")))}
                              key={x.id}
                            /> 
                          )
                        })
                      }
                      <Route
                        path="/TemplateForm/:id"
                        component={waitFor(TemplateForm)}
                      />  

                      {/* <Route
                        path="/AddShippingOrder/:id"
                        component={waitFor(lazy(() => import("./routes/shippingOrder")))}
                      /> */}
                      {/* <Route path="/Clients" component={waitFor(SubMenu)} />
                      <Route
                        path="/CatalogMaster"
                        component={waitFor(Catalog)}
                      />
                      
                      
                      <Route
                        path="/Customer"
                        component={waitFor(Customer)}
                      />
                      <Route
                        path="/Partner"
                        component={waitFor(Partner)}
                      />
                      <Route
                        path="/CustomerList"
                        component={waitFor(CustomerList)}
                      /> 
                      <Route
                        path="/PartnerList"
                        component={waitFor(PartnerList)}
                      /> 
                      <Route
                        path="/Employee"
                        component={waitFor(Epmployee)}
                      />
                      <Route
                        path="/EmployeeList"
                        component={waitFor(EmployeeList)}
                      /> 
                      <Route
                        path="/Quotation/:id"
                        component={waitFor(Quotation)}
                      />   
                      <Route
                        path="/ShippingOrder/:id"
                        component={waitFor(ShippingOrder)}
                      />
                      <Route
                        path="/CopyQuotation/:id"
                        component={waitFor(CopyQuotation)}
                      />  
                      <Route
                        path="/QuotationPrint/:id"
                        component={waitFor(QuotationPrint)}
                      />   
                      <Route
                        path="/QuotationList"
                        component={waitFor(QuotationList)}
                      />  
                      <Route
                        path="/ShippingOrderList"
                        component={waitFor(ShippingOrderList)}
                      />   
                      <Route
                        path="/TemplateList"
                        component={waitFor(TemplateList)}
                      />  
         
                      <Route
                        path="/AddUser/:id"
                        component={waitFor(AddUser)}
                      />   
                      <Route
                        path="/ChangePassword/:id"
                        component={waitFor(ChangePassword)}
                      />   
                      <Route
                        path="/ListUser"
                        component={waitFor(ListUser)}
                      /> 
                      <Route
                        path="/AddMenu/:id"
                        component={waitFor(AddMenu)}
                      />   
                      <Route
                        path="/ListMenu"
                        component={waitFor(ListMenu)}
                      /> 
                      <Route
                        path="/test"
                        component={waitFor(test)}
                      />       */}
                      <Redirect to="/Home" />
                    </Switch>                
                  </Suspense>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Base>
        )}
      </div>
    );
  }
};

export default withRouter(Routes);
