import { react, useState } from "react";
import * as Bootstrap from "reactstrap";
import axios from "axios";
import baseUrl from "../../api/constantUrl";
import "../../styles/pages/master/index.css";
import { Form, Input, Alert } from "antd";

const Logins = (props) => {
  const { setShowMenu } = props;  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [messageError, setMessageError ] = useState('');

  const onFinish = (user) => {
    setLoading(true);
    axios
      .post(`${baseUrl()}token/`, {
        username: user.username,
        password: user.password,
      })
      .then(({ data }) => {
        setLoading(false);
        if (data.access) {                    
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + data.access;
          localStorage.setItem("accessToken", data.access);  
          buildMenu();               
        } else {
          setError(true);
          setMessageError("Oops! something went wrong. Please try again.");
        }
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response && error.response.status === 401) {
          setError(true);
          setMessageError("The username or password is incorrect. Please try again.");
        } else {
          setError(true);
          setMessageError("Connection failed, please try again.");
        }
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const buildMenu = async () => {
    setLoading(true);
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.accessToken}`
      },
    };

    const urlMenuTree = `${baseUrl()}security/user/getMenuTree/`

    const response = await fetch(urlMenuTree, requestOptions);

    if (!response.ok) {
      setLoading(false);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    
    localStorage.setItem('routes', JSON.stringify(data.routes));
    localStorage.setItem('menuTree', JSON.stringify(data.menu));
                  
    localStorage.setItem("menu", true)
    setShowMenu(true);   
    setLoading(false);
    location.reload();
  }

  return (
    <div className="login-card">
      <div className="block-center mt-4 wd-xl">
        <div className="card card-flat">
          <div className="card-header text-center bg-dark">
            <a href="">
              <h1 className="menu">Freight Systems</h1>
              <h4 className="menu">Welcome!</h4>
            </a>
          </div>
          <div className="card-body">
            <p className="text-center py-2">SIGN IN TO CONTINUE.</p>
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              {error && (
                <Alert
                  message="Error!"
                  description={messageError}
                  type="error"
                />
              )}
              <Bootstrap.Button
                color="primary"
                className="btn btn-block mt-3"
                disabled={loading}
                type="submit"
              >
                {loading && (
                  <Bootstrap.Spinner size="sm">Loading...</Bootstrap.Spinner>
                )}
                <span> Login</span>
              </Bootstrap.Button>
            </Form>
          </div>
        </div>
        <div className="p-3 text-center">
          <span className="mr-2">&copy;</span>
          <span>2022</span>
          <span className="mx-2">-</span>
          <span>Freight Systems</span>
        </div>
      </div>
    </div>
  );
};

export default Logins;
