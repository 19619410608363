
let menu = [];
try{
  menu = JSON.parse(localStorage.getItem("menuTree"));
}catch{
  menu = [];
}

// [

//   {
//     heading: "Main Navigation",
//     translate: "sidebar.heading.HEADER",
//   },
//   {
//     name: "Home",
//     path: "/Home",
//     icon: "icon-grid",
//     translate: "sidebar.nav.Dashboard",
//   },
//   {
//     name: "Configuration",
//     path: "",
//     icon: "fa fa-users-cog",
//     translate: "sidebar.nav.Dashboard",
//     submenu: [
//       {
//         name: "Add User",
//         path: `/AddUser/${0}`,
//       },
//       {
//         name: "List User",
//         path: `/ListUser`,
//       },
//       {
//         name: "Add Menu",
//         path: `/AddMenu/${0}`,
//       },
//       {
//         name: "List Menu",
//         path: `/ListMenu`,
//       },
//     ],
//   },
//   {
//     name: "Catalog",
//     icon: "icon-note",
//     translate: "sidebar.nav.MENU",
//     submenu: [
//       {
//         name: "Catalog Master",
//         translate: "sidebar.nav.MASTERCATALOG",
//         path: `/catalogMaster`,
//       },
//       {
//         name: "Template",
//         translate: "sidebar.nav.MASTERCATALOG",
//         path: "/TemplateList",
//       },
//     ],
//   },
//   {
//     name: "Clients",
//     icon: "fa fa-user-tie",
//     translate: "sidebar.nav.MENU",
//     submenu: [
//       {
//         name: "New",
//         translate: "sidebar.nav.MASTERCATALOG",
//         path: `/Customer`,
//       },
//       {
//         name: "List",
//         translate: "sidebar.nav.MASTERCATALOG",
//         path: "/CustomerList",
//       },
//     ],
//   },
//   {
//     name: "Partners",
//     icon: "fa fa-address-card",
//     translate: "sidebar.nav.MENU",
//     submenu: [
//       {
//         name: "New",
//         translate: "sidebar.nav.MASTERCATALOG",
//         path: `/Partner`,
//       },
//       {
//         name: "List",
//         translate: "sidebar.nav.MASTERCATALOG",
//         path: "/PartnerList",
//       },
//     ],
//   },
//   {
//     name: "Employees",
//     icon: "fa fa-users",
//     translate: "sidebar.nav.MENU",
//     submenu: [
//       {
//         name: "New",
//         translate: "sidebar.nav.MASTERCATALOG",
//         path: `/Employee`,
//       },
//       {
//         name: "List",
//         translate: "sidebar.nav.MASTERCATALOG",
//         path: "/EmployeeList",
//       },
//     ],
//   },
//   {
//     name: "Quotation",
//     icon: "fa fa-book",
//     translate: "sidebar.nav.MENU",
//     submenu: [
//       {
//         name: "New",
//         path: `/Quotation/${0}`,
//       },
//       {
//         name: "List",
//         path: "/QuotationList",
//       },
//     ],
//   },
//   {
//     name: "Shipping Order",
//     icon: "fa fa-file-pdf",
//     submenu: [
//       {
//         name: "List",
//         translate: "sidebar.nav.MASTERCATALOG",
//         path: "/ShippingOrderList",
//       },
//     ],
//   },
// ];

export default menu;
