import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import  history from '../utils/history';
import baseURL from './constantUrl';
// Obtain the fresh token each time the function is called

//  Function that will be called to refresh authorization
//  eslint-disable-next-line arrow-body-style
const refreshAuthLogic = failedRequest => {
  const _baseUrl = baseURL();
  return axios.post(`${_baseUrl}token/refresh/`, {
    refresh: localStorage.getItem('refreshToken')
  })
    .then(response => {
      localStorage.setItem('accessToken', response.data.access);

      // eslint-disable-next-line no-param-reassign
      failedRequest.response.config.headers.authorization = `Bearer ${localStorage.getItem('accessToken')}`;

      return Promise.resolve();
    }).catch(error => {
      localStorage.setItem('menu',false);
      location.reload()
      console.warn('Cannot refresh session', error);
      history.push('/');
    });
};

const instance = axios.create({
  baseURL: baseURL()
});

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(instance, refreshAuthLogic);

// Use interceptor to inject the token to requests
instance.interceptors.request.use(config => {
  // eslint-disable-next-line no-param-reassign
  config.headers.authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  return config;
});

export default instance;
