import axios from "../axiosConfig";

export const getUserInformation = () =>
  axios.get(`/security/user/getUserInformation/`);

export const getUser = (id) => axios.get(`/security/user/${id}/`);

export const getMenu = (id) => axios.get(`/security/menu/${id}/`);

export const getMenuTree = () => axios.get(`/security/user/getMenuTree/`)

export const getMenuConfiguration = (id) => axios.get(`/security/roleDetail/${id}/getMenuConfiguration/`);

export const getUserList = () => axios.get(`/security/user/`);

export const getMenuList = () => axios.get(`/security/menu/`);

export const getParentList = () => axios.get(`/security/menu/getParentList/`);

export const getTreeRolMenu = () => axios.get(`/security/user/getTreeRolMenu/`);

export const getRoleList = () => axios.get(`/security/role/`);

export const saveUser = (data) => axios.post(`/security/user/`, data);

export const saveMenu = (data) => axios.post("/security/menu/", data);

export const saveRole = (data) => axios.post("/security/role/", data);

export const saveRoleDetail = (data) =>
  axios.post("/security/roleDetail/", data);

export const updateUser = (data, id) =>
  axios.put(`/security/user/${id}/`, data);

export const updateMenu = (data, id) =>
  axios.put(`/security/menu/${id}/`, data);

export const updateRole = (data, id) =>
  axios.put(`/security/role/${id}/`, data);

export const changePassword = (data, id) =>
  axios.put(`/security/user/${id}/changePassword/`, data);

export const updateRoleDetail = (data, id) =>
  axios.put(`/security/roleDetail/${id}/`, data);
