import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse } from "reactstrap";
import { connect } from "react-redux";
import * as apiUser from "../../api/security/index";
import moment from "moment";
import pic from "../../img/logo2.jpeg";

class SidebarUserBlock extends Component {
  state = {
    showUserBlock: true,
    userInformation: "",
    imageUrl: pic,
  };

  componentDidUpdate(oldProps) {
    if (oldProps.showUserBlock !== this.props.showUserBlock) {
      this.setState({ showUserBlock: this.props.showUserBlock });
    }
  }

  componentDidMount = async () => {
    const request = await apiUser.getUserInformation();
    localStorage.setItem("user", JSON.stringify(request.data.user));
    this.setState({ userInformation: request.data.user });
  };

  render() {
    return (
      <Collapse id="user-block" isOpen={this.state.showUserBlock}>
        <div>
          <div className="item user-block">
            {/* User picture */}
            <div className="user-block-picture">
              <div className="user-block-status">
                <img
                  className="img-thumbnail rounded-circle"
                  src={this.state.imageUrl}
                  alt="Avatar"
                  width="60"
                  height="60"
                />
                <div className="circle bg-success circle-lg"></div>
              </div>
            </div>
            {/* Name and Job */}
            <div className="user-block-info">
              {this.state.userInformation[0] && (
                <span className="user-block-name">
                  Hello,{" "}
                  {`${this.state.userInformation[0].firstName} ${this.state.userInformation[0].lastName}`}
                </span>
              )}
              {this.state.userInformation[0] && (
                <span className="user-block-role">
                  Last visit:{" "}
                  {moment(this.state.userInformation[0].lastLogin).format(
                    "DD/MM/YYYY  HH:MM a"
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
      </Collapse>
    );
  }
}

SidebarUserBlock.propTypes = {
  showUserBlock: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  showUserBlock: state.settings.showUserBlock,
});

export default connect(mapStateToProps)(SidebarUserBlock);
