import { React, Fragment, useState } from "react";
import { Button, Tooltip, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ButtonCustom = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const {
    color,
    onClick,
    icon,
    text,
    tooltip,
    id,
    type,
    disabled,
    showSpinner,
    className
  } = props;
  return (
    <Fragment>
      <Tooltip
        target={id}
        placement="right"
        toggle={toggle}
        isOpen={tooltipOpen}
      >
        {tooltip}
      </Tooltip>
      <Button
        className={className}
        color={color}
        onClick={onClick}
        id={id}
        type={type}
        disabled={disabled || showSpinner}
      >
        {!showSpinner && <FontAwesomeIcon icon={icon}></FontAwesomeIcon>}
        {showSpinner && <Spinner size="sm">Loading...</Spinner>} {text}
      </Button>
    </Fragment>
  );
};

export default ButtonCustom;
